/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.took {
  background-color: lightblue;
  color: gray; /* Changes the text color to gray */
  text-decoration: line-through; /* Adds a line through the text */
  opacity: 0.7; /* Makes the item slightly transparent */
}

/* AdminLayout styles */
:root {
  --primary-color: #001157b4; /* Replace with the exact color from the logo */
  --text-color: #ffffff; /* Assuming white text on primary color background */
}

.admin-layout {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: var(--primary-color);
  color: var(--text-color);
  position: fixed;
  height: 100%;
  transition: transform 0.3s ease; /* Smooth transition for toggling */
  z-index: 1000; /* Ensure sidebar is on top of the main content */
}

.sidebar.collapsed {
  transform: translateX(-250px); /* Hide the sidebar */
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.logo {
  width: 150px; /* Adjust size as needed */
  height: auto;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
}

.sidebar-menu li {
  margin: 15px 0;
}

.sidebar-menu a {
  color: var(--text-color);
  text-decoration: none;
}

.sidebar-menu a:hover {
  text-decoration: underline;
}

.main-content {
  margin-left: 250px;
  padding: 20px;
  transition: margin-left 0.3s ease; /* Smooth transition for content shift */
}

.main-content.collapsed {
  margin-left: 0; /* Adjust content when sidebar is hidden */
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 10px 20px;
  width: calc(100vw - 250px); /* Adjust width based on sidebar */
  position: fixed;
  top: 0;
  z-index: 1000;
}

.footer {
  background-color: var(--primary-color);
  color: var(--text-color);
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: calc(100vw - 250px); /* Adjust width based on sidebar */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-250px); /* Initially hide the sidebar */
  }

  .sidebar.collapsed {
    transform: translateX(0); /* Show sidebar when toggled */
  }

  .main-content {
    margin-left: 0; /* Full width content when sidebar is hidden */
  }

  .header {
    width: 100vw; /* Full width for header */
  }

  .footer {
    width: 100vw; /* Full width for footer */
  }
}
