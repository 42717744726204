.content {
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  }
  
  .collapsed {
    max-height: 0;
    opacity: 0;
  }
  
  .expanded {
    max-height: 50000px; /* Adjust this value based on your content */
    opacity: 1;
  }
  